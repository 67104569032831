// Define outerHtml method for jQuery since we need to give openlayers plain html
// http://stackoverflow.com/questions/2419749/get-selected-elements-outer-html
// Elements outerHtml property only works on IE and chrome
jQuery.fn.outerHTML = function (arg) {
  var ret;

  // If no items in the collection, return
  if (!this.length) {
    return typeof arg === 'undefined' ? this : null;
  }
  // Getter overload (no argument passed)
  if (!arg) {
    return this[0].outerHTML || (ret = this.wrap('<div>').parent().html(), this.unwrap(), ret);
  }
  // Setter overload
  jQuery.each(this, function (i, el) {
    var fnRet,
      pass = el,
      inOrOut = el.outerHTML ? 'outerHTML' : 'innerHTML';
    if (!el.outerHTML) {
      el = jQuery(el).wrap('<div>').parent()[0];
    }
    if (jQuery.isFunction(arg)) {
      if ((fnRet = arg.call(pass, i, el[inOrOut])) !== false) {
        el[inOrOut] = fnRet;
      }
    } else {
      el[inOrOut] = arg;
    }
    if (!el.outerHTML) {
      jQuery(el).children().unwrap();
    }
  });
  return this;
};

// FROM: https://stackoverflow.com/questions/39245488/event-path-undefined-with-firefox-and-vue-js?noredirect=1&lq=1
// Event.composedPath
// Possibly normalize to add window to Safari's chain, as it does not?
(function (E, d, w) {
  if (!E.composedPath) {
    E.composedPath = function () {
      if (this.path) {
        return this.path;
      }
      var target = this.target;
      this.path = [];
      while (target.parentNode !== null) {
        this.path.push(target);
        target = target.parentNode;
      }
      this.path.push(d, w);
      return this.path;
    };
  }
})(Event.prototype, document, window);