import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { timeUnits } from './constants';
dayjs.extend(customParseFormat);
var calculateYearDifference = function calculateYearDifference(from, to) {
  return from - to;
};
var calculateMonthDifference = function calculateMonthDifference(from, to) {
  return dayjs(to).diff(dayjs(from), timeUnits.MONTH);
};
var calculateDayDifference = function calculateDayDifference(from, to) {
  return dayjs(to).diff(dayjs(from), timeUnits.DAY);
};
var calculateHourDifference = function calculateHourDifference(from, to) {
  return dayjs(to).diff(dayjs(from), timeUnits.HOUR);
};
export var getDifferenceCalculator = function getDifferenceCalculator(unit) {
  switch (unit) {
    case timeUnits.YEAR:
      return calculateYearDifference;
    case timeUnits.MONTH:
      return calculateMonthDifference;
    case timeUnits.DAY:
      return calculateDayDifference;
    case timeUnits.HOUR:
      return calculateHourDifference;
    default:
      return calculateYearDifference;
  }
};
export var calculateSvgX = function calculateSvgX(clientX, svg) {
  var ctm = svg.getScreenCTM();
  return (clientX - ctm.e) / ctm.a;
};