import { DEFAULT_COLORS, DEFAULT_FONT } from './constants';
import { EFFECT } from '../../constants';
export var getHeaderTheme = function getHeaderTheme(theme) {
  var _theme$color, _theme$color2, _theme$color3;
  var bgColor = ((_theme$color = theme.color) === null || _theme$color === void 0 || (_theme$color = _theme$color.header) === null || _theme$color === void 0 ? void 0 : _theme$color.bg) || ((_theme$color2 = theme.color) === null || _theme$color2 === void 0 ? void 0 : _theme$color2.primary) || DEFAULT_COLORS.HEADER_BG;
  var headerTextColor = getTextColor(bgColor);
  var accentColor = ((_theme$color3 = theme.color) === null || _theme$color3 === void 0 ? void 0 : _theme$color3.accent) || DEFAULT_COLORS.ACCENT;
  var funcs = {
    getBgColor: function getBgColor() {
      return bgColor;
    },
    getAccentColor: function getAccentColor() {
      return accentColor;
    },
    getBgBorderColor: function getBgBorderColor() {
      return Oskari.util.getColorEffect(accentColor, -10);
    },
    getBgBorderBottomColor: function getBgBorderBottomColor() {
      return Oskari.util.getColorEffect(accentColor, 20);
    },
    getTextColor: function getTextColor() {
      var _theme$color4;
      return ((_theme$color4 = theme.color) === null || _theme$color4 === void 0 || (_theme$color4 = _theme$color4.header) === null || _theme$color4 === void 0 ? void 0 : _theme$color4.text) || headerTextColor;
    },
    getToolColor: function getToolColor() {
      var _theme$color5;
      return ((_theme$color5 = theme.color) === null || _theme$color5 === void 0 || (_theme$color5 = _theme$color5.header) === null || _theme$color5 === void 0 ? void 0 : _theme$color5.icon) || funcs.getTextColor();
    },
    getToolHoverColor: function getToolHoverColor() {
      return accentColor;
    }
  };
  return funcs;
};

// https://ant.design/docs/react/customize-theme
export var getAntdTheme = function getAntdTheme(theme) {
  // Note! The theme parameter is not used here,
  //  but the Oskari theme is passed here so we _could_ make some adjustments based on that
  return {
    // algorithm: antdTheme.defaultAlgorithm,
    components: {
      Button: {
        // colorPrimary: accentColor,
        // controlItemBgHover: headerTextColor,
        // colorFillContentHover: accentColor
        // fixes an issue where close icon has white bg while hovering
        colorBgContainer: 'inherit'
      },
      Slider: {
        handleColor: DEFAULT_COLORS.SLIDER_BG,
        trackBg: DEFAULT_COLORS.SLIDER_BG,
        trackHoverBg: DEFAULT_COLORS.SLIDER_HOVER
      }
    },
    token: {
      // Seed Token
      // colorPrimary: '#00b96b',
      // colorPrimary "antD blue" seems to work better than using accent from Oskari theme at the moment
      colorPrimary: 'rgb(24, 144, 255)',
      // accentColor,
      // use the font selected from Oskari theme/global, otherwise antd defaults to its own font spec
      fontFamily: 'inherit',
      // make buttons more like v4, the default is a bit more rounder
      borderRadius: 2
      // Setting colorBgContainer: 'inherit' here makes the table th column bg color black for some reason
      // colorBgContainer: 'inherit'
    }
  };
};
export var getNavigationTheme = function getNavigationTheme(theme) {
  var _theme$navigation, _theme$navigation2, _theme$navigation3, _theme$navigation5;
  var primary = ((_theme$navigation = theme.navigation) === null || _theme$navigation === void 0 || (_theme$navigation = _theme$navigation.color) === null || _theme$navigation === void 0 ? void 0 : _theme$navigation.primary) || DEFAULT_COLORS.DARK_BUTTON_BG;
  var accent = ((_theme$navigation2 = theme.navigation) === null || _theme$navigation2 === void 0 || (_theme$navigation2 = _theme$navigation2.color) === null || _theme$navigation2 === void 0 ? void 0 : _theme$navigation2.accent) || theme.color.accent || DEFAULT_COLORS.ACCENT;
  var textColor = getTextColor(primary);
  var borderRadius = 0;
  if (theme !== null && theme !== void 0 && (_theme$navigation3 = theme.navigation) !== null && _theme$navigation3 !== void 0 && _theme$navigation3.roundness) {
    var _theme$navigation4;
    borderRadius = ((theme === null || theme === void 0 || (_theme$navigation4 = theme.navigation) === null || _theme$navigation4 === void 0 ? void 0 : _theme$navigation4.roundness) || 0) / 2;
  }
  var buttonColor = primary;
  if (((_theme$navigation5 = theme.navigation) === null || _theme$navigation5 === void 0 ? void 0 : _theme$navigation5.effect) === '3D') {
    var start = Oskari.util.getColorEffect(primary, EFFECT.DARKEN);
    var stop = Oskari.util.getColorEffect(primary, EFFECT.LIGHTEN);
    buttonColor = "linear-gradient(180deg, ".concat(start, " 0%, ").concat(primary, " 35%, ").concat(stop, " 100%)");
  }
  var funcs = {
    getPrimary: function getPrimary() {
      return primary;
    },
    getAccent: function getAccent() {
      return accent;
    },
    getAccentHover: function getAccentHover() {
      return Oskari.util.getColorEffect(accent, 30);
    },
    getTextColor: function getTextColor() {
      var _theme$navigation6;
      return ((_theme$navigation6 = theme.navigation) === null || _theme$navigation6 === void 0 || (_theme$navigation6 = _theme$navigation6.color) === null || _theme$navigation6 === void 0 ? void 0 : _theme$navigation6.text) || textColor;
    },
    getButtonColor: function getButtonColor() {
      return buttonColor;
    },
    getButtonHoverColor: function getButtonHoverColor() {
      return accent;
    },
    // like 50%
    getButtonRoundness: function getButtonRoundness() {
      return "".concat(borderRadius, "%");
    },
    // like 0.5 for calc() usage
    getButtonRoundnessFactor: function getButtonRoundnessFactor() {
      return borderRadius / 100;
    },
    getEffect: function getEffect() {
      var _theme$navigation7;
      return (_theme$navigation7 = theme.navigation) === null || _theme$navigation7 === void 0 ? void 0 : _theme$navigation7.effect;
    },
    getButtonOpacity: function getButtonOpacity() {
      var _theme$navigation8;
      return ((_theme$navigation8 = theme.navigation) === null || _theme$navigation8 === void 0 ? void 0 : _theme$navigation8.opacity) || 1;
    },
    getNavigationBackgroundColor: function getNavigationBackgroundColor() {
      var _theme$navigation9, _theme$navigation10;
      return ((_theme$navigation9 = theme.navigation) === null || _theme$navigation9 === void 0 || (_theme$navigation9 = _theme$navigation9.color) === null || _theme$navigation9 === void 0 ? void 0 : _theme$navigation9.bg) || ((_theme$navigation10 = theme.navigation) === null || _theme$navigation10 === void 0 || (_theme$navigation10 = _theme$navigation10.color) === null || _theme$navigation10 === void 0 ? void 0 : _theme$navigation10.primary) || DEFAULT_COLORS.NAV_BG;
    }
  };
  return funcs;
};
export var getTextColor = function getTextColor(bgColor) {
  if (Oskari.util.isDarkColor(bgColor)) {
    return '#FFFFFF';
  }
  ;
  return '#000000';
};
export var getFontClass = function getFontClass(theme) {
  var fontClassSuffix = theme.font || DEFAULT_FONT;
  return 'oskari-theme-font-' + fontClassSuffix;
};