export var playbackSpeedOptions = {
  HOUR: 'HH',
  MONTH: 'MM',
  YEAR: 'YYYY'
};
export var timeUnits = {
  HOUR: 'hour',
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year'
};
export var sliderTypes = {
  YEAR: 'year',
  DATE: 'date'
};