function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import { THEMING } from './theming';
import './global';

// Tests not using global jQuery so this is not needed.
if (typeof jQuery !== 'undefined') {
  // So IE won't use a cached xhr result -> adds a _=timestamp param for each request...
  jQuery.ajaxSetup({
    cache: false
  });
}
(function (o) {
  if (!o) {
    // can't add loader if no Oskari ref
    return;
  }
  // Cross-site request forgery protection with cookie based token
  function getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    var result = ca.map(function (cook) {
      return cook.trim();
    }).filter(function (cook) {
      return cook.indexOf(name) === 0;
    }).map(function (cook) {
      return cook.substring(name.length, cook.length);
    });
    if (result.length > 0) {
      return result[0];
    }
  }
  var csrfToken = getCookie('XSRF-TOKEN');
  if (csrfToken) {
    jQuery(document).ajaxSend(function (e, xhr, options) {
      xhr.setRequestHeader('X-XSRF-TOKEN', csrfToken);
    });
  }
  // /CSRF

  var App = function App() {
    /**
     * @property appSetup
     * application startup sequence
     */
    this.appSetup = null;

    /**
     * @property appConfig
     * application configuration (state) for instances
     * this is injected to instances before 'start' is called
     */
    this.appConfig = {};
  };

  /**
   * FACADE will have only a couple of methods which trigger alotta operations
   */
  App.prototype = {
    /**
     * @public @method getBundleInstanceConfigurationByName
     * Returns configuration for instance by bundleinstancename
     *
     * @param  {string} biid Bundle instance ID
     *
     * @return {Object}      Bundle instance configuration
     */
    getBundleInstanceConfigurationByName: function getBundleInstanceConfigurationByName(biid) {
      return this.appConfig[biid];
    },
    /**
     * @public @method playBundle
     * Plays a bundle player JSON object by instantiating any required
     * bundle instances.
     *
     * @param {Object}           recData  Bundle player JSON
     * @param {function(Object)} callback Callback function
     *
     */
    playBundle: function playBundle(recData, config, callback) {
      if (_typeof(recData) !== 'object') {
        throw new Error('Bundle def is not an object');
      }
      if (typeof config === 'function') {
        callback = config;
        config = undefined;
      }
      if (config) {
        // wrap to acceptable format
        var configName = recData.bundleinstancename || recData.bundlename;
        var tmp = {};
        tmp[configName] = config;
        config = tmp;
      } else {
        config = this.appConfig;
      }
      var loader = Oskari.loader([recData], config);
      // send suppress event flag as true since this is used to trigger single bundle starts for testing mostly (via dev-console etc)
      loader.processSequence(callback, true);
    },
    /**
     * Convenience function to load appsetup from url with given params and startup the Oskari app.
     * @param  {String}   url       Url to load the appsetup json from
     * @param  {Object}   params    Optional parameters to pass for the request
     * @param  {Function} errorCB   Optional callback for handling error
     * @param  {Function} successCB Optional callback that is called when the application has started
     * @param  {Function} modifyCB  Optional callback that is called appsetup is loaded, but before it's used by Oskari
     */
    loadAppSetup: function loadAppSetup(url, params, errorCB, successCB, modifyCB) {
      var me = this;
      jQuery.ajax({
        type: 'GET',
        dataType: 'json',
        data: params || {},
        url: url,
        success: function success(setup) {
          if (typeof modifyCB === 'function') {
            modifyCB(setup);
          }
          me.init(setup);
          me.startApplication(successCB);
        },
        error: function error(jqXHR) {
          if (typeof errorCB === 'function') {
            errorCB(jqXHR);
          }
        }
      });
    },
    /**
     * @public @method init
     * Initializes the internal state so startApplication() can be called to startup the initialized app.
     *
     * @param {Object} setup JSON application setup {
     *     startupSequence: [ <bundledef1>, <bundledef2>, ...],
     *     env: { ... },
     *     configuration: { ... }
     *   }
     */
    init: function init(setup) {
      var _setup$env$app2;
      this.appSetup = setup;
      if (setup.configuration) {
        this.setConfiguration(setup.configuration);
      }
      setup.env = setup.env || {};
      if (typeof Oskari.setLang === 'function') {
        Oskari.setLang(setup.env.lang || window.language);
      }
      if (typeof Oskari.setSupportedLocales === 'function') {
        Oskari.setSupportedLocales(setup.env.locales);
      }
      if (typeof Oskari.setDecimalSeparator === 'function') {
        Oskari.setDecimalSeparator(setup.env.decimalSeparator);
      }
      if (_typeof(Oskari.custom) === 'object') {
        var _setup$env$app;
        Oskari.custom.setMarkers(setup.env.svgMarkers);
        Oskari.custom.setDefaultStyle(setup.env.oskariStyle);
        Oskari.custom.setThemeColors((_setup$env$app = setup.env.app) === null || _setup$env$app === void 0 ? void 0 : _setup$env$app.theme);
      }
      if (typeof Oskari.user === 'function') {
        Oskari.user(setup.env.user);
      }
      if ((_setup$env$app2 = setup.env.app) !== null && _setup$env$app2 !== void 0 && _setup$env$app2.theme) {
        THEMING.setTheme(setup.env.app.theme);
      }
      Oskari.urls.set(setup.env.urls);
    },
    /**
     * @public @method setApplicationSetup
     * @deprecated Use init() instead.
     *
     * @param {Object} setup JSON application setup {
     *     startupSequence: [ <bundledef1>, <bundledef2>, ...],
     *     env: { ... },
     *     configuration: { ... }
     *   }
     */
    setApplicationSetup: function setApplicationSetup(setup) {
      if (window.console && window.console.warn) {
        console.warn('Oskari.app.setApplicationSetup() is deprecated. Use Oskari.app.init() instead.');
      }
      this.init(setup);
    },
    /**
     * Returns the identifier for this appsetup (if loaded from oskari-server/db)
     * @return {String}
     */
    getUuid: function getUuid() {
      var env = this.getApplicationSetup().env || {};
      var app = env.app || {};
      return app.uuid;
    },
    /**
     * Returns appsetup type like "user", "published" etc
     * @return {String}
     */
    getType: function getType() {
      var env = this.getApplicationSetup().env || {};
      var app = env.app || {};
      return app.type;
    },
    /**
     * Returns true if this appsetup is publicly available.
     * Returns false if it's a non-public personal view of a user.
     * @return {Boolean}
     */
    isPublic: function isPublic() {
      var env = this.getApplicationSetup().env || {};
      var app = env.app || {};
      return !!app.public;
    },
    /**
     * Returns the token for xsrf
     */
    getXSRFToken: function getXSRFToken() {
      return csrfToken;
    },
    /**
     * @public @method getApplicationSetup
     * @return {Object} Application setup
     */
    getApplicationSetup: function getApplicationSetup() {
      return this.appSetup || {};
    },
    /**
     * @public @method setConfiguration
     * @param {Object} config Config
     */
    setConfiguration: function setConfiguration(config) {
      this.appConfig = config;
    },
    /**
     * @public @method getConfiguration
     * @return {Object}
     */
    getConfiguration: function getConfiguration() {
      return this.appConfig || {};
    },
    startApplication: function startApplication(callback) {
      var loader = Oskari.loader(this.appSetup.startupSequence, this.appConfig);
      loader.processSequence(callback);
    },
    /**
     * @method stopApplication
     * Might stop app if/when all stops implemented
     */
    stopApplication: function stopApplication() {
      throw new Error('Not supported');
    },
    /**
     * @method getSystemDefaultViews
     * @return {Object[]} default view objects Array
     */
    getSystemDefaultViews: function getSystemDefaultViews() {
      return this.appSetup.env.defaultApps || [];
    },
    getTheming: function getTheming() {
      return THEMING;
    }
  };
  o.app = new App();
})(Oskari);