function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import { PLACEMENTS } from ".";
export var getAvailableWidth = function getAvailableWidth() {
  // width of <body>
  return document.body.clientWidth ||
  // width of <html>
  document.documentElement.clientWidth ||
  // window's width
  window.innerWidth;
};
export var getAvailableHeight = function getAvailableHeight() {
  // height of <body>
  return document.body.clientHeight ||
  // height of <html>
  document.documentElement.clientHeight ||
  // window's height
  window.innerHeight;
};
var DEFAULT_WIDTH = 700;
var DEFAULT_HEIGHT = 700;
var BORDER_MARGIN = 50;
export var OUTOFSCREEN_CLASSNAME = 'outofviewport';
export var createDraggable = function createDraggable(position, setPosition, elementRef) {
  if (_typeof(position) !== 'object') {
    throw new TypeError('Pass an object with x and y keys as first param');
  }
  if (typeof setPosition !== 'function') {
    throw new TypeError('Pass function to update position as second param');
  }
  if (!elementRef) {
    throw new TypeError('Pass React.useRef() for the element to move as third param');
  }
  var element = elementRef.current;
  var width = DEFAULT_WIDTH;
  var height = DEFAULT_HEIGHT;
  if (element) {
    var bounds = element.getBoundingClientRect();
    width = bounds.width;
    height = bounds.height;
  }
  var availableWidth = getAvailableWidth();
  var availableHeight = getAvailableHeight();
  var halfWidth = width / 2;
  var halfHeight = height / 2;
  var screenTopLimit = -5;
  // previousTouch is assigned in onTouchMove to track change and onMouseUp for reset
  var previousTouch;
  var onTouchMove = function onTouchMove(event) {
    // prevents text selection from other elements while dragging
    event.preventDefault();
    var element = elementRef.current;
    if (!element) {
      return;
    }
    var touch = event.touches[0];
    if (previousTouch) {
      onGenericMove({
        x: touch.pageX - previousTouch.pageX,
        y: touch.pageY - previousTouch.pageY
      });
    }
    ;
    previousTouch = touch;
  };
  var onMouseMove = function onMouseMove(event) {
    // prevents text selection from other elements while dragging
    event.preventDefault();
    var element = elementRef.current;
    if (!element) {
      return;
    }
    onGenericMove({
      x: event.movementX,
      y: event.movementY
    });
  };
  var onGenericMove = function onGenericMove(delta) {
    position.x += delta.x;
    position.y += delta.y;
    var outFromLeft = position.x < -halfWidth;
    var outFromRight = position.x + halfWidth > availableWidth;
    var outFromUp = position.y < screenTopLimit; // the header should remain visible to make it possible to drag back on screen
    var outFromBottom = position.y + halfHeight > availableHeight;
    var outOfScreen = outFromLeft || outFromRight || outFromUp || outFromBottom;
    if (!outOfScreen) {
      element.classList.remove(OUTOFSCREEN_CLASSNAME);
      // don't make the actual move if we would move off-screen or we don't get an element to move
      element.style.transform = "translate(".concat(position.x, "px, ").concat(position.y, "px)");
      setPosition(position);
    } else {
      element.classList.add(OUTOFSCREEN_CLASSNAME);
      if (outFromLeft) {
        setPosition(_objectSpread(_objectSpread({}, position), {}, {
          x: -halfWidth
        }));
      } else if (outFromRight) {
        setPosition(_objectSpread(_objectSpread({}, position), {}, {
          x: Math.max(availableWidth - halfWidth, 0)
        }));
      } else if (outFromUp) {
        setPosition(_objectSpread(_objectSpread({}, position), {}, {
          y: screenTopLimit
        }));
      } else if (outFromBottom) {
        setPosition(_objectSpread(_objectSpread({}, position), {}, {
          y: Math.max(availableHeight - halfHeight, 0)
        }));
      }
    }
  };
  var _onMouseUp = function onMouseUp() {
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseup", _onMouseUp);
    document.removeEventListener("touchmove", onTouchMove);
    document.removeEventListener("touchend", _onMouseUp);
    document.removeEventListener("touchcancel", _onMouseUp);
    previousTouch = null;
  };
  document.addEventListener("mousemove", onMouseMove);
  document.addEventListener("mouseup", _onMouseUp);
  document.addEventListener("touchmove", onTouchMove, {
    passive: false
  });
  document.addEventListener("touchend", _onMouseUp);
  document.addEventListener("touchcancel", _onMouseUp);
};
var getPlacementXY = function getPlacementXY(width, height, placement) {
  var availableWidth = getAvailableWidth();
  var availableHeight = getAvailableHeight();
  var x = Math.max((availableWidth - width) / 2, 0);
  var y = Math.max((availableHeight - height) / 2, 0);
  switch (placement) {
    case PLACEMENTS.TOP:
      y = BORDER_MARGIN;
      break;
    case PLACEMENTS.LEFT:
      x = BORDER_MARGIN;
      break;
    case PLACEMENTS.BOTTOM:
      y = availableHeight - height - BORDER_MARGIN;
      break;
    case PLACEMENTS.RIGHT:
      x = availableWidth - width - BORDER_MARGIN;
      break;
    case PLACEMENTS.TL:
      y = BORDER_MARGIN;
      x = BORDER_MARGIN;
      break;
    case PLACEMENTS.TR:
      y = BORDER_MARGIN;
      x = availableWidth - width - BORDER_MARGIN;
      break;
    case PLACEMENTS.BL:
      y = availableHeight - height - BORDER_MARGIN;
      x = BORDER_MARGIN;
      break;
    case PLACEMENTS.BR:
      y = availableHeight - height - BORDER_MARGIN;
      x = availableWidth - width - BORDER_MARGIN;
      break;
  }
  return {
    x: x,
    y: y
  };
};
export var getPositionForCentering = function getPositionForCentering(elementRef, placement) {
  // ref.current is used for React.useRef(), element is just plain old "div" or similar
  var isReactRef = !!(elementRef && elementRef.current);
  var isHtmlEl = elementRef instanceof Element;
  if (!isReactRef && !isHtmlEl) {
    throw new TypeError('Pass React.useRef() or HTMLElement for the element to center');
  }
  var element = elementRef.current || elementRef;
  var width = DEFAULT_WIDTH;
  var height = DEFAULT_HEIGHT;
  if (element) {
    var bounds = element.getBoundingClientRect();
    // breaks if element is 0x0 so use defaults instead
    width = bounds.width || DEFAULT_WIDTH;
    height = bounds.height || DEFAULT_HEIGHT;
  }
  return getPlacementXY(width, height, placement);
};

/**
 * Create ResizeObserver handler for an element to detect if browser window size changes to
 *  try and keep elements in viewport if they would be out of screen after size change.
 * @param {React.useRef()} elementRef for element that we want to keep on screen
 * @param {Object} position with x and y keys
 * @param {Function} setPosition to set new position
 * @returns function to register for monitoring
 */
export var createDocumentSizeHandler = function createDocumentSizeHandler(elementRef, position, setPosition) {
  return function (newSize, prevSize) {
    var windowIsNowBigger = prevSize.width < newSize.width || prevSize.height < newSize.height;
    var elementNoLongerOnScreen = position.x > newSize.width || position.y > newSize.height;
    if (elementRef.current && (windowIsNowBigger || elementNoLongerOnScreen)) {
      // Note! The class is added in createDraggable()
      // but we might not be able to remove it there after recentering on window size change
      // remove it if window is now bigger
      elementRef.current.classList.remove(OUTOFSCREEN_CLASSNAME);
    }
    if (elementNoLongerOnScreen) {
      // console.log('Element relocating! Window size changed from', prevSize, 'to', newSize);
      setPosition(_objectSpread(_objectSpread({}, position), {}, {
        centered: false
      }));
    }
  };
};

/**
 * Create ResizeObserver handler for an element to detect if it's size changes in a way that would make the element bleed out of screen from the left or bottom.
 * Tries to keep element in viewport by moving it up/left if it would grow large enough to go out of screen.
 * @param {React.useRef()} elementRef for element that we want to keep on screen
 * @param {Object} position with x and y keys
 * @param {Function} setPosition to set new position
 * @returns function to register for monitoring
 */
export var createDraggableSizeHandler = function createDraggableSizeHandler(elementRef, position, setPosition) {
  return function (newSize, prevSize) {
    var popupGotBigger = prevSize.width < newSize.width || prevSize.height < newSize.height;
    if (!elementRef.current || !popupGotBigger) {
      // got smaller, we don't need to relocate
      return;
    }
    var x = Math.min(getAvailableWidth() - newSize.width, position.x);
    var y = Math.min(getAvailableHeight() - newSize.height, position.y);
    if (x !== position.x || y !== position.y) {
      setPosition({
        x: x,
        y: y,
        centered: false
      });
    }
  };
};