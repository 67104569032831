/**
 * Prefix object keys with "data-" so we can safely pass them to DOM element
 * @param {String} format point, line or area
 * @param {Object} props flags for style selection based on format
 */
export var getAsDataAttributes = function getAsDataAttributes(format, style) {
  var props = getPropsForFormat(format, style);
  var testAttrs = {
    'data-format': format
  };
  Object.keys(props).forEach(function (key) {
    testAttrs['data-' + key] = props[key];
  });
  return testAttrs;
};

/**
 * Returns a simplified object based on format for generating preview SVG
 * @param {String} format geometry type: point, line or area
 * @param {Object} style Oskari style object
 * @returns simplified style object for SVG generator
 */
var getPropsForFormat = function getPropsForFormat(format, style) {
  if (format === 'point') {
    return getPointPropsFromStyle(style);
  } else if (format === 'line') {
    return getLinePropsFromStyle(style);
  } else if (format === 'area') {
    return getAreaPropsFromStyle(style);
  }
  throw new Error("Unrecognized format");
};
var getAreaPropsFromStyle = function getAreaPropsFromStyle(style) {
  var _style$stroke, _fill$area;
  var _style$fill = style.fill,
    fill = _style$fill === void 0 ? {} : _style$fill;
  var stroke = ((_style$stroke = style.stroke) === null || _style$stroke === void 0 ? void 0 : _style$stroke.area) || {};
  return {
    color: fill.color,
    strokecolor: stroke.color,
    size: stroke.width,
    strokestyle: stroke.lineDash,
    linejoin: stroke.lineJoin,
    pattern: (_fill$area = fill.area) === null || _fill$area === void 0 ? void 0 : _fill$area.pattern
  };
};
var getLinePropsFromStyle = function getLinePropsFromStyle() {
  var style = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _style$stroke2 = style.stroke,
    stroke = _style$stroke2 === void 0 ? {} : _style$stroke2;
  return {
    color: stroke.color,
    size: stroke.width,
    linecap: stroke.lineCap,
    linedash: stroke.lineDash,
    linejoin: stroke.lineJoin
  };
};
var getPointPropsFromStyle = function getPointPropsFromStyle() {
  var style = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _style$image = style.image,
    image = _style$image === void 0 ? {} : _style$image;
  var _image$fill = image.fill,
    fill = _image$fill === void 0 ? {} : _image$fill;
  return {
    color: fill.color,
    size: image.size,
    shape: image.shape
  };
};