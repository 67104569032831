export var TYPE = {
  POPUP: 'popup',
  FLYOUT: 'flyout',
  CONTAINER: 'container',
  BANNER: 'banner',
  SIDE_PANEL: 'sidePanel',
  MODAL: 'modal'
};
// activeComponents has id + type as key and remove function as value
var activeComponents = {};
var getRegisterKey = function getRegisterKey(id, type) {
  return type + '_' + id;
};
export var REGISTER = {
  clear: function clear(key) {
    if (key) {
      if (typeof activeComponents[key] === 'function') {
        // call remove function
        activeComponents[key]();
      }
      // remove reference to window
      delete activeComponents[key];
    } else {
      Object.values(activeComponents).forEach(function (o) {
        return typeof o === 'function' && o();
      });
      activeComponents = {};
    }
  },
  registerWindow: function registerWindow(id, type, removeFn) {
    var key = getRegisterKey(id, type);
    activeComponents[key] = removeFn;
    return key;
  },
  getExistingWindow: function getExistingWindow(id, type) {
    return activeComponents[getRegisterKey(id, type)];
  }
};