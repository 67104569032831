function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
/*
The base html is:
------
div.oskari-root-el
    (nav)
    (div.functionality-sidebar)
    div.oskari-map-container-el
        div.oskari-map-impl-el
------
nav - optional (geoportal has it, embedded does not)
- expected to be present on the HTML if needed
- holds the main menu with "tiles", toolbar etc and it can also be after map-container for right-handed navigation

div.functionality-sidebar - optional
- created at runtime by functionalities
- functionality specific sidebar for things like the publisher, printout, analysis etc
- nav is usually hidden and this is prepended to .oskari-root-el when active

div.oskari-map-container-el
- created at runtime by core functionality OR mapped to an existing element with id #contentMap
- tries to stretch as much as there is space available as container where the map component is located in
- limits the maximum size of map

div.oskari-map-impl-el
- created at runtime by core functionality OR mapped to an existing element with id #mapdiv
- root for the actual map engine impl == 'target' for OpenLayers Map
- sets the size of the map: usually as large as oskari-map-container-el, but in publisher the preview size is set by limiting the size of this element

*/

// constants for constructing/referencing the base HTML
var DEFAULT_ROOT_EL_ID = 'oskari';
var MAP_CONTAINER_ID = 'contentMap';
var MAP_IMPL_ID = 'mapdiv';
var ROOT_EL_CLASS_FOR_STYLING = 'oskari-root-el';
var MAP_CONTAINER_EL_CLASS_FOR_STYLING = 'oskari-map-container-el';
var MAP_IMPL_EL_CLASS_FOR_STYLING = 'oskari-map-impl-el';
var APP_EMBEDDED_CLASS = 'published';
var getBodyTag = function getBodyTag() {
  return document.getElementsByTagName('body')[0];
};
var rootEl;
var mapContainerEl;
var mapImplEl;
var setRootEl = function setRootEl(id) {
  if (id) {
    rootEl = document.getElementById(id);
  }
  if (!rootEl) {
    rootEl = getBodyTag();
    if (!rootEl.style.height) {
      // rendering directly to body and no height set
      // -> set CSS to follow expected styling/assume full screen app
      rootEl.style.height = '100vh';
    }
  }
  // use styles from .oskari-root-el for body like display: flex
  rootEl.classList.add(ROOT_EL_CLASS_FOR_STYLING);
  // generate other DOM elements
  mapContainerEl = document.getElementById(MAP_CONTAINER_ID);
  if (!mapContainerEl) {
    mapContainerEl = document.createElement('div');
    // do we need the id here?
    mapContainerEl.setAttribute('id', MAP_CONTAINER_ID);
    rootEl.append(mapContainerEl);
  }
  mapContainerEl.classList.add(MAP_CONTAINER_EL_CLASS_FOR_STYLING);
  mapImplEl = document.getElementById(MAP_IMPL_ID);
  if (!mapImplEl) {
    mapImplEl = document.createElement('div');
    // do we need the id here?
    mapImplEl.setAttribute('id', MAP_IMPL_ID);
    mapContainerEl.append(mapImplEl);
  }
  mapImplEl.classList.add(MAP_IMPL_EL_CLASS_FOR_STYLING);
  // TODO: navigation?
  return rootEl;
};
var getRootEl = function getRootEl() {
  if (!rootEl) {
    return setRootEl(DEFAULT_ROOT_EL_ID);
  }
  return rootEl;
};
var getMapContainerEl = function getMapContainerEl() {
  if (!rootEl) {
    setRootEl(DEFAULT_ROOT_EL_ID);
  }
  return mapContainerEl;
};
var getMapImplEl = function getMapImplEl() {
  if (!rootEl) {
    setRootEl(DEFAULT_ROOT_EL_ID);
  }
  return mapImplEl;
};
var getWidth = function getWidth() {
  var el = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : getRootEl();
  return el.clientWidth || parseInt(getComputedStyle(el).getPropertyValue('width'));
};
var getHeight = function getHeight() {
  var el = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : getRootEl();
  return el.clientHeight || parseInt(getComputedStyle(el).getPropertyValue('height'));
};
var isEmbedded = function isEmbedded() {
  // 'published' is assumed to be in map container as class when the app is in "embedded mode"
  // publisher functionality sets this to fake it
  // published.jsp on server has it by default
  return getMapContainerEl().classList.contains(APP_EMBEDDED_CLASS);
};
var showNavigation = function showNavigation(show) {
  var nav = getNavigationEl();
  if (nav) {
    if (show) {
      nav.style.display = 'block';
      setMenuScrollIndicator();
    } else {
      nav.style.display = 'none';
    }
  }
};
var isNavigationVisible = function isNavigationVisible() {
  var nav = getNavigationEl();
  if (!nav) return false;
  var style = window.getComputedStyle(nav);
  return style.getPropertyValue('display') !== 'none';
};
var getNavigationEl = function getNavigationEl() {
  return _toConsumableArray(Oskari.dom.getRootEl().children).find(function (c) {
    return c.localName === 'nav';
  });
};
var setMenuScrollIndicator = function setMenuScrollIndicator() {
  var indicatorNotNeeded = function indicatorNotNeeded() {
    var navEl = Oskari.dom.getNavigationEl();
    if (!navEl) return true;
    var scrollTop = navEl.scrollTop;
    var scrollHeight = navEl.scrollHeight;
    var offsetHeight = navEl.offsetHeight;
    var contentHeight = scrollHeight - offsetHeight;
    return contentHeight <= scrollTop;
  };
  var setIndicator = function setIndicator(event) {
    window.requestAnimationFrame(function () {
      if (indicatorNotNeeded()) {
        getNavigationEl().classList.remove('show-scroll-icon');
      } else {
        getNavigationEl().classList.add('show-scroll-icon');
      }
    });
  };
  var navElement = getNavigationEl();
  if (navElement) {
    setIndicator();
    if (!navElement.querySelector('.scroll-indicator')) {
      var scrollIndicator = document.createElement('div');
      scrollIndicator.className = 'scroll-indicator';
      var scrollIcon = document.createElement('div');
      scrollIcon.className = 'scroll-icon';
      scrollIndicator.appendChild(scrollIcon);
      navElement.appendChild(scrollIndicator);
      navElement.addEventListener('scroll', setIndicator);
      if (indicatorNotNeeded()) {
        getNavigationEl().classList.remove('show-scroll-icon');
      }
    }
  }
};
export var DOMHelper = {
  setRootEl: setRootEl,
  getRootEl: getRootEl,
  getMapContainerEl: getMapContainerEl,
  getMapImplEl: getMapImplEl,
  isEmbedded: isEmbedded,
  getWidth: getWidth,
  getHeight: getHeight,
  showNavigation: showNavigation,
  isNavigationVisible: isNavigationVisible,
  getNavigationEl: getNavigationEl,
  setMenuScrollIndicator: setMenuScrollIndicator,
  APP_EMBEDDED_CLASS: APP_EMBEDDED_CLASS
};