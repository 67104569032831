(function (o) {
  var log = Oskari.log('Oskari.deprecated');

  // Warn 2 times before falling silent
  var warn = function warn(name) {
    log.deprecated('Oskari.' + name + '()');
  };
  var mode = 'default';
  var domMgr;
  var dollarStore = o.createStore();
  var _ctrlKeyDown = false;
  var _isDebug = false;
  var funcs = {
    /**
     * @public @method Oskari.setDebugMode
     * @param {boolean} d Debug mode on/off
     */
    setDebugMode: function setDebugMode(d) {
      _isDebug = !!d;
    },
    isDebug: function isDebug() {
      return _isDebug;
    },
    /**
     * @public @static @method Oskari.setLoaderMode
     * @param {string} m Loader mode
     */
    setLoaderMode: function setLoaderMode(m) {
      mode = m;
    },
    /**
     * @public @method Oskari.getLoaderMode
     * @return {string} Loader mode
     */
    getLoaderMode: function getLoaderMode() {
      return mode;
    },
    /**
     * @public @method Oskari.setPreloaded
     * @deprecated No longer has any effect. Remove calls to it. Will be removed in 1.38 or later.
     */
    setPreloaded: function setPreloaded() {},
    purge: function purge() {},
    getDomManager: function getDomManager() {
      return domMgr;
    },
    setDomManager: function setDomManager(dm) {
      domMgr = dm;
    },
    /**
     * @public @method Oskari.$
     * @return {}
     */
    $: function $(name, value) {
      return dollarStore.data(name, value);
    },
    setSandbox: function setSandbox(name, sandbox) {},
    /**
     * @method handleCtrlKeyDownRequest
     * Sets flag to show that CTRL key is pressed down
     * @private
     */
    ctrlKeyDown: function ctrlKeyDown(isDown) {
      if (typeof isDown === 'undefined') {
        // getter
        return _ctrlKeyDown;
      }
      // setter
      _ctrlKeyDown = !!isDown;
    }
  };
  var attachWarning = function attachWarning(name) {
    return function () {
      warn(name);
      return funcs[name].apply(o, arguments);
    };
  };
  // attach to Oskari with a warning message wrapping
  for (var key in funcs) {
    o[key] = attachWarning(key);
  }
})(Oskari);