function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var hasObserver = typeof ResizeObserver !== 'undefined';
var elementMap = {};
/**
 * Detect new size for Observer entry
 * @param  entry 
 * @returns object with width and height keys
 */
var getNewSize = function getNewSize(entry) {
  // width:  200  is  200px
  // height:  68  is  68px
  //console.log('width: ', entry.borderBoxSize[0].inlineSize, ' is ', cs.width);
  //console.log('height: ', entry.borderBoxSize[0].blockSize, ' is ', cs.height);
  var result = {
    width: entry.borderBoxSize[0].inlineSize,
    height: entry.borderBoxSize[0].blockSize
  };
  return result;
};
var SIZE_OBSERVER = hasObserver ? new ResizeObserver(function (entries) {
  var _iterator = _createForOfIteratorHelper(entries),
    _step;
  try {
    var _loop = function _loop() {
      var entry = _step.value;
      var result = getNewSize(entry);
      var prev = getCurrentData(getObserverKey(entry.target));
      if (prev) {
        //console.log('Size changed from', prev.size, 'to', result);
        var prevSize = prev.size;
        prev.size = result;
        if (prevSize.width !== result.width || prevSize.height !== result.height) {
          // ensure there is a change
          // TODO: throttle calls
          prev.listeners.forEach(function (fn) {
            return fn(result, prevSize, entry.target);
          });
        }
      }
    };
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      _loop();
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}) : null;

/**
 * @param {HTMLElement} element
 * @returns {Object} with references to the element, previously recorded size and listeners
 */
var getCurrentData = function getCurrentData(element) {
  if (typeof element !== 'string') {
    var key = getObserverKey(element);
    return elementMap[key];
  }
  return elementMap[element];
};
var observerIdentSeq = Oskari.getSeq('ResizeObservers');
var getObserverKey = function getObserverKey(element) {
  if (!element) {
    return;
  }
  var key = element.dataset.oskari_observer;
  if (!key) {
    key = 'RO_' + observerIdentSeq.nextVal();
  }
  element.dataset.oskari_observer = key;
  return key;
};

/**
 * Starts monitoring element for size changes and notifies listeners
 * @param {HTMLElement} element to monitor
 * @param {Function} notifyFn function to be called with new and previous size
 */
export var monitorResize = function monitorResize(element, notifyFn) {
  if (!element) {
    return;
  }
  var elementKey = getObserverKey(element);
  var currentMonitor = getCurrentData(elementKey);
  if (currentMonitor && typeof notifyFn === 'function') {
    currentMonitor.listeners.push(notifyFn);
  } else {
    SIZE_OBSERVER && SIZE_OBSERVER.observe(element);
    elementMap[elementKey] = {
      element: element,
      listeners: [notifyFn].filter(function (fn) {
        return typeof fn === 'function';
      }),
      size: {
        width: element.offsetWidth,
        height: element.offsetHeight
      }
    };
  }
};

/**
 * Removes function from monitoring listeners
 * @param {Function} notifyFn function used when monitorResize was called
 */
export var unmonitorResize = function unmonitorResize(notifyFn) {
  var removableElements = [];
  Object.keys(elementMap).forEach(function (element) {
    var listeners = elementMap[element].listeners;
    var newListeners = listeners.filter(function (fn) {
      return fn !== notifyFn;
    });
    if (!newListeners.length) {
      removableElements.push(element.element);
    } else {
      elementMap[element].listeners = newListeners;
    }
  });
  removableElements.forEach(function (el) {
    return unmonitorElement(el);
  });
};
/**
 * Once all listeners have been removed can be used to stop listening to changes
 * @param {HTMLElement} element
 */
var unmonitorElement = function unmonitorElement(element) {
  if (!element) {
    return;
  }
  try {
    SIZE_OBSERVER && SIZE_OBSERVER.unobserve(element);
  } catch (ignored) {}
  var elementKey = getObserverKey(element);
  delete elementMap[elementKey];
};