function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
/*
 * ***************************************************************************************************************************
 * Functions in this file are used to map the logic between AntD form ("flat object" with keys as dot-separated paths referencing parts of oskari style JSON)
 *  and Oskari style (deep structure that needs to be flattened for AntD)
 * ***************************************************************************************************************************
 */
var deepCopy = function deepCopy(style) {
  return JSON.parse(JSON.stringify(style));
};
/**
 * Takes original oskariStyle as param and returns a function that can be used to get an updated style from AntD form. 
 * The returned function takes param like { 'image.shape': 3}, breaks the dot-separated key to parts and navigates style object structure based on that,
 * updates the referenced object value (for example oskariStyle.image.shape to 3) and returns the modified style
 * @param {Object} originalStyle oskari style JSON that we are going to use as base for updating
 * @returns the modified style (not mutated originalStyle but a new object) 
 */
var createStyleAdjuster = function createStyleAdjuster() {
  var originalStyle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var style = deepCopy(originalStyle);
  return function (changes) {
    // changes is like: {image.shape: 3}
    Object.keys(changes).forEach(function (key) {
      var keyParts = key.split('.');
      var partialStyle = style;
      while (keyParts.length) {
        var partialKey = keyParts.shift();
        if (!keyParts.length) {
          // found the leaf node. Set value to partialStyle
          // modifies the style variable as well as partialStyle is a reference to part of it
          partialStyle[partialKey] = changes[key];
          break;
        }
        // recurse deeper
        var nextStep = partialStyle[partialKey];
        if (typeof nextStep === 'undefined') {
          // add missing node/create missing structure
          nextStep = {};
          partialStyle[partialKey] = nextStep;
        }
        // set new partialStyle for next iteration
        partialStyle = nextStep;
      }
    });
    // return modified style
    return style;
  };
};

/**
 * Maps an object with deep structure to a flat object with keys having dots to signify structure.
 * Used to feed AntD form an object that populates fields based on Oskari style JSON.
 * @param {Object} style
 */
var createFlatFormObjectFromStyle = function createFlatFormObjectFromStyle() {
  var style = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _flattenStyle(style, {});
};
var _flattenStyle = function flattenStyle() {
  var input = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var result = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var baseKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  if (_typeof(input) !== 'object') {
    if (baseKey.length) {
      // leaf value
      result[baseKey] = input;
    }
    return result;
  }
  var newBaseKey = '';
  if (baseKey.length > 0) {
    newBaseKey = baseKey + '.';
  }
  Object.keys(input).forEach(function (styleKey) {
    return _flattenStyle(input[styleKey], result, newBaseKey + styleKey);
  });
  return result;
};
export var FormToOskariMapper = {
  createStyleAdjuster: createStyleAdjuster,
  createFlatFormObjectFromStyle: createFlatFormObjectFromStyle,
  deepCopy: deepCopy
};