var UnsupportedLayerSrs = Oskari.clazz.get('Oskari.mapframework.domain.UnsupportedLayerSrs');
/**
 * @class Oskari.projection.change.instance
 *
 *      var obj = {
            "bundlename":"asdi-projection-change"
        }
        appSetup.startupSequence.push(obj);
 */
Oskari.clazz.define('Oskari.projection.change.instance', function () {
  this.sandbox = null;
  this.loc = null;
}, {
  __name: 'projection-change',
  getName: function getName() {
    return this.__name;
  },
  start: function start() {
    this.sandbox = Oskari.getSandbox();
    this.loc = Oskari.getLocalization('projection-change');
    this._mapmodule = this.sandbox.findRegisteredModuleInstance('MainMapModule');
    this.createPlugin();
    this.createUi();
    this._overrideUnsupportedLayerActions();
  },
  getViews: function getViews() {
    return this.getAppViews();
  },
  createPlugin: function createPlugin() {
    var params = {
      views: this.getAppViews(),
      loc: this.loc,
      sb: this.sandbox
    };
    var plugin = Oskari.clazz.create('Oskari.projection.change.ProjectionChangerPlugin', params, this.loc);
    this._mapmodule.registerPlugin(plugin);
    this._mapmodule.startPlugin(plugin);
    this.plugin = plugin;
  },
  stopPlugin: function stopPlugin() {
    this._mapmodule.unregisterPlugin(this.plugin);
    this._mapmodule.stopPlugin(this.plugin);
    this.plugin = null;
  },
  createUi: function createUi() {
    this.plugin.createUi();
  },
  getAppViews: function getAppViews() {
    return Oskari.app.getSystemDefaultViews();
  },
  _overrideUnsupportedLayerActions: function _overrideUnsupportedLayerActions() {
    var _this = this;
    var srs = new UnsupportedLayerSrs();
    srs.setAction(function () {
      return _this.plugin.showPopup();
    });
    srs.setActionText(this.loc.changeProjection);
    this.sandbox.getMap().addLayerSupportCheck(srs);
  }
});