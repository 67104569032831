function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var AUTO = 'auto';
var DARKEN = 'darken';
var LIGHTEN = 'lighten';
var MINOR = 'minor';
var NORMAL = 'normal';
var MAJOR = 'major';
export var DELTA = _defineProperty(_defineProperty(_defineProperty({}, MINOR, 60), NORMAL, 90), MAJOR, 120);
export var DEFAULT_DELTA = DELTA[NORMAL];
export var EFFECT = {
  NONE: 'none',
  AUTO: AUTO,
  DARKEN: DARKEN,
  LIGHTEN: LIGHTEN,
  AUTO_MINOR: "".concat(AUTO, " ").concat(MINOR),
  AUTO_NORMAL: "".concat(AUTO, " ").concat(NORMAL),
  AUTO_MAJOR: "".concat(AUTO, " ").concat(MAJOR),
  DARKEN_MINOR: "".concat(DARKEN, " ").concat(MINOR),
  DARKEN_NORMAL: "".concat(DARKEN, " ").concat(NORMAL),
  DARKEN_MAJOR: "".concat(DARKEN, " ").concat(MAJOR),
  LIGHTEN_MINOR: "".concat(LIGHTEN, " ").concat(MINOR),
  LIGHTEN_NORMAL: "".concat(LIGHTEN, " ").concat(NORMAL),
  LIGHTEN_MAJOR: "".concat(LIGHTEN, " ").concat(MAJOR)
};